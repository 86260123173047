<script lang="ts" setup>
import MenuDesktop from '~/components/landing/MenuDesktop.vue'

definePageMeta({
  layout: 'landing',
})

useSeo({
  title: `Discover Unlimited Streaming of Your Favorite Radio Stations Worldwide. Sports, News, Talk, Politics, and Music—All in One Place`,
  description: `Zeno.FM is the fastest and easiest way to listen and create streaming radio. We specialize in international content from Brazil, Mexico, Colombia, Haiti, Argentina, Ghana, Peru, Guatemala, Spain, and many more!`,
})
</script>

<template>
  <div class="min-w-80">
    <header class="bg-zeno-blue-light relative overflow-hidden hidden-scroll pb-20">
      <div class="max-w-[1920px] mx-auto">
        <div class="lg:hidden">
          <MobileNavigation />
        </div>
        <div class="lg:block hidden">
          <MenuDesktop />
        </div>
      </div>

      <div
        class="max-w-[1920px] mx-auto lg:px-8 px-4 flex lg:flex-row flex-col justify-between lg:space-x-6 space-x-0 lg:space-y-0 space-y-96 relative overflow-hidden"
      >
        <div class="relative z-10 min-w-96 pt-2">
          <LandingHero />
        </div>

        <div class="relative z-10 lg:w-[500px] sm:min-w-96">
          <LandingListenNow />
        </div>
      </div>

      <div
        class="md:top-48 top-96 left-1/2 transform -translate-x-1/2 lg:w-[1000px] w-[120%] min-w-[450px] min-h-[450px] inline-block aspect-square bg-gradient-to-b from-[#fec009] to-[#ed8c08] absolute rounded-full z-0"
      >
        <NuxtImg
          :preload="true"
          alt=""
          class="object-cover object-center mx-auto relative -left-5 top-20 lg:w-5/12 w-5/12"
          loading="eager"
          src="/img/landing-hero1.webp"
        />
      </div>
    </header>

    <main class="bg-zeno-gray-light">
      <div class="container max-w-screen-xl mx-auto py-20 sm:px-8 px-4 space-y-48">
        <div class="flex lg:flex-row flex-col-reverse lg:space-x-8 space-x-0">
          <div class="flex items-center lg:w-1/2 w-full">
            <LandingWheel />
          </div>

          <div class="flex items-center lg:w-1/2 w-full ld:mb-0 mb-16">
            <LandingDiscoverBlock />
          </div>
        </div>

        <div class="grid lg:grid-cols-2 grid-cols-1 lg:gap-8 gap-16">
          <div class="flex items-center">
            <LandingCreateBlock />
          </div>

          <div class="flex items-center">
            <LandingFeaturesList />
          </div>
        </div>

        <div>
          <LandingPartnersList />
        </div>
      </div>
    </main>

    <footer class="container max-w-screen-xl mx-auto py-4 sm:px-8 px-4">
      <div class="flex lg:flex-row flex-col justify-between lg:space-x-12 space-x-0 lg:space-y-0 space-y-12 py-24">
        <div class="space-y-16">
          <div>
            <MainLogo />
          </div>

          <LandingDownloadApp />
        </div>

        <LandingFooterNavigation />
      </div>

      <LandingLegalLinks />
    </footer>
  </div>
</template>

<style scoped></style>
