<script setup lang="ts">
const links = [
  {
    name: 'Privacy',
    route: '/privacy-policy/',
  },
  {
    name: 'Terms',
    route: '/terms/',
  },
  {
    name: 'Cookies',
    route: '/cookies-policy/',
  },
  {
    name: 'GDPR',
    route: '/gdpr-privacy-policy/',
  },
  { name: 'Contact us', route: 'mailto:plans@zeno.fm' },
]
</script>

<template>
  <div class="pt-4 border-t border-[#EAE9EB} text-zeno-gray flex justify-between md:flex-row flex-col-reverse">
    <div>© 2024 Zeno FM</div>
    <nav class="md:mb-0 mb-4">
      <ul class="flex flex-wrap justify-between md:space-x-12">
        <li v-for="link in links" :key="link.name">
          <a :href="link.route" target="_blank">{{ link.name }}</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style scoped></style>
