<script setup lang="ts"></script>

<template>
  <div class="rounded-2xl bg-zeno-gray-light max-w-96 flex">
    <div class="flex items-end">
      <NuxtImg src="/img/mobile-app.webp" class="rounded-2xl h-48" alt="Download App" />
    </div>
    <div class="w-8/12 p-6 gap grid-cols-1 content-center">
      <div class="grid grid-cols-1 gap-4">
        <div>
          <div class="p-2 bg-zeno-yellow inline-block rounded-lg">
            <img src="../../public/img/logo.svg" alt="zeno.fm logo" class="h-5 w-5" />
          </div>
        </div>

        <div class="font-semibold">Listen to your favorite live radio and podcasts with Zeno App</div>

        <div>
          <a
            target="_blank"
            :href="oneLinkUrl()"
            class="bg-zeno-text inline-block rounded-full text-white px-5 p-2 text-sm font-semibold text-center"
          >
            Free Download
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
