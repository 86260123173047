<script setup lang="ts"></script>

<template>
  <section class="space-y-8">
    <h2 class="md:text-8xl sm:text-7xl text-6xl font-monigue uppercase">Create & grow your own radio station</h2>

    <p class="lg:text-xl font-semibold text-lg text-zeno-gray">
      Known for our online streaming platform, we are proud to constantly offer new features to help grow your station.
    </p>

    <div class="flex">
      <NuxtLink to="/signup/" class="text-center py-4 px-8 bg-zeno-yellow font-semibold rounded-full">
        Start Your 14-Day Free Trial
      </NuxtLink>
    </div>
  </section>
</template>

<style scoped></style>
