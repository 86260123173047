<script setup lang="ts">
const features = [
  [
    {
      icon: 'solar:translation-bold',
      title: 'Live Broadcasting',
      description: 'Unlimited listeners, bandwidth, and relays creation',
    },
    {
      icon: 'solar:diagram-up-bold',
      title: 'Analytics',
      description: 'Up to 6 months. Long term data available for Plan members',
    },
    {
      icon: 'solar:dollar-linear',
      title: 'Monetization',
      description: 'Get paid for audio ads played on your stations',
    },
    {
      icon: 'solar:playlist-bold',
      title: 'Record your shows',
      description: 'Create a playlist or a podcast with recordings from your live broadcast',
    },
  ],
  [
    {
      icon: 'solar:music-library-linear',
      title: 'Auto-DJ',
      description: 'Set up a playlist for when you are offline',
    },
    {
      icon: 'solar:server-minimalistic-linear',
      title: 'Podcast Hosting',
      description: 'Easily create, distribute and track your podcast with unlimited storage.',
    },
    {
      icon: 'solar:phone-calling-linear',
      title: 'Call-to-listen Line',
      description:
        'Let your listeners access your station without Internet data by using their phone minutes plan. Only available in the USA',
    },
  ],
]
</script>

<template>
  <div class="grid xl:grid-cols-2 lg:grid-cols-1 sm:grid-cols-2 grid-cols-1 gap-4 text-sm">
    <div class="grid grid-cols-1 gap-4">
      <div v-for="(feature, index) in features[0]" :key="index" class="rounded-[20px] bg-white p-4 flex space-x-3">
        <div class="pt-1">
          <Icon size="22" :name="feature.icon" />
        </div>
        <div class="flex-1 space-y-2">
          <div class="font-semibold">{{ feature.title }}</div>
          <div class="text-zeno-gray">{{ feature.description }}</div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-4">
      <div v-for="(feature, index) in features[1]" :key="index" class="rounded-[20px] bg-white p-4 flex space-x-3">
        <div class="pt-1">
          <Icon size="22" :name="feature.icon" />
        </div>
        <div class="flex-1 space-y-2">
          <div class="font-semibold">{{ feature.title }}</div>
          <div class="text-zeno-gray">{{ feature.description }}</div>
        </div>
      </div>

      <a
        href="/pricing/"
        class="flex justify-between items-center cursor-pointer p-3 border-2 border-gray-200 rounded-[20px]"
      >
        <span class="font-semibold">Learn more about Plans</span>
        <Icon size="22" name="solar:arrow-right-linear" />
      </a>
    </div>
  </div>
</template>

<style scoped></style>
