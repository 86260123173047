<script setup lang="ts"></script>

<template>
  <nav class="flex justify-between items-center z-10 p-8">
    <MainLogo />
    <div class="flex gap-3">
      <NuxtLink :to="{ name: 'radio-genres-genre', params: { genre: 'Religious' } }" class="menu-link">
        Religious
      </NuxtLink>
      <NuxtLink :to="{ name: 'radio-genres-genre', params: { genre: 'Music' } }" class="menu-link">Music</NuxtLink>
      <NuxtLink :to="{ name: 'radio-genres-genre', params: { genre: 'News/Talk' } }" class="menu-link">
        News & Talk
      </NuxtLink>
      <NuxtLink :to="{ name: 'podcasts' }" class="menu-link">Podcasts</NuxtLink>
    </div>
    <div class="flex items-center gap-3">
      <NuxtLink to="/signup/" class="menu-link"> Sign up</NuxtLink>
      <NuxtLink to="/login/" class="rounded-full px-4 py-1 bg-zeno-text text-white"> Log in</NuxtLink>
    </div>
  </nav>
</template>

<style lang="postcss" scoped>
.menu-link {
  @apply px-4 py-1 bg-white rounded-full;
}
</style>
