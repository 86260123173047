<script setup lang="ts"></script>

<template>
  <div class="grid grid-cols-1 gap-8">
    <div class="text-center text-lg font-semibold">Trusted by world’s most respected outlets</div>
    <div class="flex flex-wrap justify-center">
      <div class="md:w-1/5 w-1/3 p-4">
        <NuxtImg src="/img/partners/npr.webp" alt="NPR" />
      </div>
      <div class="md:w-1/5 w-1/3 p-4">
        <NuxtImg src="/img/partners/estrella-media.webp" alt="Estrella Media" />
      </div>
      <div class="md:w-1/5 w-1/3 p-4">
        <NuxtImg src="/img/partners/bbc.webp" alt="BBC" />
      </div>
      <div class="md:w-1/5 w-1/3 p-4">
        <NuxtImg src="/img/partners/the-washington-post.webp" alt="The Washington Post" />
      </div>
      <div class="md:w-1/5 w-1/3 p-4">
        <NuxtImg src="/img/partners/rfi.webp" alt="Radio France Internationale" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
