<script setup lang="ts"></script>

<template>
  <div class="relative lg:space-y-0 space-y-8 w-full">
    <div
      class="flex flex-col justify-center sm:space-y-24 space-y-4 sm:absolute w-full sm:left-1/2 sm:top-1/2 sm:-translate-x-1/2 sm:-translate-y-1/3"
    >
      <div class="sm:flex block justify-center">
        <div class="px-5 py-3.5 flex items-center space-x-4 font-semibold bg-white rounded-full">
          <span class="p-2 bg-zeno-yellow rounded-full inline-flex items-center">
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle
                cx="7.35613"
                cy="7.85418"
                r="5.61785"
                stroke="#131018"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.2617 12.0532L13.4642 14.25"
                stroke="#131018"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span class="flex">Explore content by country, language, genre and creator</span>
        </div>
      </div>

      <div class="sm:flex block justify-center">
        <div class="px-5 py-3.5 flex items-center space-x-4 font-semibold bg-white rounded-full">
          <span class="p-2 bg-[#F26327] rounded-full inline-flex items-center">
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.86696 3.73814C6.74726 3.73814 6.66537 3.64994 6.64647 3.53654C6.50157 2.52226 6.49527 2.51596 5.45579 2.33956C5.31719 2.31436 5.24159 2.23877 5.24159 2.11907C5.24159 1.99937 5.31719 1.92377 5.43689 1.89857C6.51417 1.68438 6.52047 1.69068 6.64647 0.714196C6.66537 0.588198 6.74726 0.5 6.86696 0.5C6.98036 0.5 7.06856 0.588198 7.08746 0.701596C7.22605 1.74108 7.22605 1.74738 8.29073 1.89857C8.40413 1.91747 8.49233 1.99937 8.49233 2.11907C8.49233 2.23877 8.41043 2.31436 8.29703 2.33956C7.22605 2.55376 7.23235 2.54746 7.08746 3.54284C7.06856 3.64994 6.98036 3.73814 6.86696 3.73814ZM3.78632 8.44414C3.59733 8.44414 3.45243 8.31184 3.43353 8.11655C3.23823 6.49748 3.19413 6.43448 1.51837 6.19509C1.29787 6.16359 1.17188 6.03129 1.17188 5.84229C1.17188 5.6596 1.29787 5.521 1.48057 5.4895C3.20673 5.18711 3.23823 5.16191 3.43353 3.56804C3.45243 3.36644 3.59733 3.23415 3.78632 3.23415C3.96902 3.23415 4.11392 3.36644 4.13912 3.55544C4.34701 5.22491 4.37851 5.2627 6.07948 5.4895C6.26217 5.5084 6.39447 5.6596 6.39447 5.84229C6.39447 6.01869 6.26847 6.16359 6.09208 6.19509C4.35961 6.49118 4.34701 6.52268 4.13912 8.12915C4.11392 8.31184 3.96272 8.44414 3.78632 8.44414ZM8.53013 15.5C8.20883 15.5 7.94424 15.2606 7.88754 14.9204C7.32055 11.6823 6.94886 11.3043 3.83042 10.857C3.48393 10.8066 3.23823 10.5357 3.23823 10.2018C3.23823 9.86161 3.49023 9.58442 3.83672 9.54662C6.96146 9.19383 7.40875 8.72764 7.88754 5.4895C7.93794 5.14931 8.20253 4.90991 8.53013 4.90991C8.85772 4.90991 9.11602 5.14931 9.17902 5.4895C9.7397 8.72764 10.1177 9.11193 13.2298 9.54662C13.5826 9.60332 13.8283 9.87421 13.8283 10.2018C13.8283 10.542 13.57 10.8192 13.2235 10.857C10.1051 11.2161 9.65151 11.6823 9.17902 14.9204C9.12862 15.2606 8.86402 15.5 8.53013 15.5Z"
                fill="#131018"
              />
            </svg>
          </span>
          <span>Multicultural stations and podcasts</span>
        </div>
      </div>

      <div class="sm:flex block justify-center">
        <div class="px-5 py-3.5 flex items-center space-x-4 font-semibold bg-white rounded-full">
          <span class="p-2 bg-zeno-blue-light rounded-full inline-flex items-center">
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.6958 6.69947C12.1379 7.14158 12.1379 7.85837 11.6958 8.30047L8.29954 11.6967C8.08724 11.909 7.79929 12.0283 7.49904 12.0283C7.1988 12.0283 6.91085 11.909 6.69854 11.6967L3.30232 8.30047C2.86022 7.85837 2.86022 7.14158 3.30232 6.69947C3.74442 6.25737 4.46121 6.25737 4.90331 6.69947L6.36697 8.16313L6.36697 1.83959C6.36697 1.21437 6.87381 0.707519 7.49904 0.707519C8.12427 0.707519 8.63112 1.21437 8.63112 1.83959L8.63112 8.16313L10.0948 6.69947C10.5369 6.25737 11.2537 6.25737 11.6958 6.69947Z"
                fill="#131018"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.83984 14.1604C1.83984 13.5352 2.34669 13.0283 2.97192 13.0283H12.0285C12.6538 13.0283 13.1606 13.5352 13.1606 14.1604C13.1606 14.7856 12.6538 15.2925 12.0285 15.2925H2.97192C2.34669 15.2925 1.83984 14.7856 1.83984 14.1604Z"
                fill="#131018"
              />
            </svg>
          </span>
          <span>Download your favorite episode</span>
        </div>
      </div>
    </div>

    <div>
      <NuxtImg src="/img/landing-discover.webp" alt="Listen to live radio & podcasts for free." class="mx-auto block" />
    </div>
  </div>
</template>

<style scoped></style>
