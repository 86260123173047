<script setup lang="ts"></script>

<template>
  <div class="bg-white p-6 relative rounded-2xl grid grid-cols-1 gap-7">
    <div class="grid grid-cols-1 gap-5">
      <div class="flex items-center justify-between">
        <h2 class="font-semibold text-xl">
          <span class="lg:inline-block hidden">Find your favorite content</span>
          <span class="lg:hidden inline-block">Listen Now</span>
        </h2>
        <NuxtLink
          :to="{ name: 'home' }"
          class="p-1.5 ps-2 pe-2 border border-zeno-gray-border rounded-full flex items-center space-x-1"
        >
          <span class="bg-zeno-yellow p-1.5 rounded-full flex">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V6.5"
                stroke="#131018"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M5.5 10.5L6.5 10.5C7.90013 10.5 8.6002 10.5 9.13498 10.2275C9.60538 9.98783 9.98783 9.60538 10.2275 9.13498C10.5 8.6002 10.5 7.90013 10.5 6.5L10.5 5.5"
                stroke="#131018"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </span>
          <span>Open app</span>
        </NuxtLink>
      </div>

      <SearchInput />

      <ExploreNavigation />
    </div>

    <section class="space-y-5">
      <div class="flex items-center justify-between">
        <h2 class="text-lg">Popular Live Radio</h2>

        <TagLink :route="{ name: 'radio' }"><span>More</span></TagLink>
      </div>

      <LazyLandingRadioList />
    </section>
  </div>
</template>

<style scoped></style>
